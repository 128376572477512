<script setup lang="ts">
import { MailIcon } from 'lucide-vue-next';
import { Logger } from 'utils';
import type { NewsletterSignupStoryblok } from '@/storyblok-types';

defineProps<{
  blok?: NewsletterSignupStoryblok;
}>();

const { locale } = useI18n();
const { BASE_URL } = useBaseUrl();
const { getToken } = useReCaptcha();

const email = ref('');
const formRef = ref<HTMLFormElement | null>(null);
const token = ref<string | null>(null);

const signUp = async () => {
  try {
    token.value = await getToken('newsletter');
  } catch (error) {
    Logger.error('Newsletter recaptcha failed', error);
  }

  if (!token.value) {
    Logger.error('No newsletter recaptcha token');
  }

  if (formRef.value) {
    nextTick(() => formRef.value?.submit());
  } else {
    Logger.error('Newsletter form reference is null');
  }
};
</script>

<template>
  <div
    v-editable="blok"
    class="bg-purple-extra-light py-12 lg:pt-16"
  >
    <div class="container max-w-7xl">
      <div class="mx-auto max-w-full px-4 sm:max-w-lg sm:px-0 lg:max-w-full">
        <div class="grid gap-2 lg:grid-cols-2 lg:gap-12">
          <div class="text-center text-purple lg:text-left">
            <h3 class="mb-4 mt-0 text-2xl font-bold md:text-3xl lg:text-4xl">
              {{ $t('footer.newsletter.title') }}
            </h3>
            <div class="text-sm text-dark">
              {{ $t('footer.newsletter.description') }}
            </div>
          </div>
          <div class="flex items-center justify-center">
            <form
              ref="formRef"
              accept-charset="utf-8"
              class="w-full"
              method="post"
              :action="`${BASE_URL}/${locale}/newsletter/subscribe`"
              @submit.prevent="signUp"
            >
              <div class="relative flex h-16 w-full items-center justify-stretch gap-2 rounded-lg border-2 border-grey bg-white p-3 pl-4 text-dark focus-within:border-purple-dark focus-within:ring-purple-dark">
                <input
                  v-model="email"
                  required
                  autocomplete="email"
                  class="block w-full flex-1 border-0 p-0 text-sm placeholder:text-grey focus:border-0 focus:outline-hidden focus:ring-0"
                  name="email"
                  type="email"
                  :placeholder="$t('footer.newsletter.placeholder')"
                />
                <Button
                  class="hidden shrink-0 sm:flex"
                  size="medium"
                  type="submit"
                  variant="info"
                >
                  <MailIcon class="size-5" />
                  {{ $t('footer.newsletter.submit') }}
                </Button>
              </div>
              <input
                name="reCaptchaToken"
                type="hidden"
                :value="token"
              />

              <Button
                class="mt-4 w-full sm:hidden"
                size="medium"
                type="submit"
                variant="info"
              >
                <MailIcon class="size-5" />
                {{ $t('footer.newsletter.submit') }}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
